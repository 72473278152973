import React from 'react'
import '../css/About.css';
import aboutJPG from'../images/about.jpg';
const About = () => (
  <div>
    <div id="about" className="aboutWrapper">
      <div className="box a"><img src={aboutJPG} alt="Head Shot" /></div>
      <div className="box b">Replicaessembler is an American industrial music band founded in 2014 in Seattle, Washington. Singer, songwriter, multi-instrumentalist, and producer Allan Bathurst is the only permanent member of the band. He collaborates with producer and recording engineer Tom Rickell on Replicaessembler releases. Chicago-area artist and close friend Ari Cebollero has also been a regular collaborator, providing artwork for all Replicaessembler releases.
      <br /><br />
      Bathurst has played guitar in various bands since 1999 including Chicago-based Device and Seattle-based Localhost. In 2014 he began experimenting with recording and production techniques and over the next 3 years composed a series of instrumentals that developed into a new music project, Replicaessembler. In early 2017 all of those tracks were arranged, mastered and released as a free collection, Instrumental Means (2017), on Soundcloud.
      <br /><br />
      In late 2017, Bathurst wrote and recorded Replicaessembler's debut EP, They Might Be Lies (2018). The track "Statelessness Protocol" was featured on Hard Rock Hell Radio's weekly Industrial, Dark Wave and EBM program, 'TheFix!' Replicaessembler's follow-up EP, Fringe Elements (2019) and the stand-alone single Carnival (2019) marked the beginning of his collaboration with Rickell. The track "Remote Viewer" was also featured on 'TheFix!' during 2019. In 2020 and 2021 Bathurst remixed and released the tracks "Statelessness Protocol" and "Following You" from They Might Be Lies (2018).
      <br /><br />
      In 2022 Bathurst began work on his first full-length album, Feudal, which is expected to release in 2024.</div>
    </div>
  </div>
)

export default About