import React from 'react'
import '../css/Discography.css';
import { ReactComponent as AppleSVG } from '../images/listen-apple.svg';
import { ReactComponent as SpotifySVG } from '../images/listen-spotify.svg';
import { ReactComponent as AmazonSVG } from '../images/listen-amazon.svg';
import { ReactComponent as YoutubeSVG } from '../images/listen-youtube.svg';
import carnivalJPG from'../images/carnival.jpg';
import fringeelementsJPG from'../images/fringe-elements.jpg';
import theymightbeliesJPG from'../images/they-might-be-lies.jpg';
const Discography = () => (
  <div>
    <div id="discography" className="discographyWrapper">
        <div className="box e"><img src={carnivalJPG} alt="Carnival" /></div>
        <div className="box f"><span className="boldText">Carnival - Single [2019]</span><br/>The perfect soundtrack to our imperfect dystopia.<br/><br/>
            <span className="boldText">TRACKLIST:</span><br/> [1] Carnival<br/><br/>
            <a href="https://music.apple.com/us/album/carnival-single/1482141838" target="_blank" rel="noopener noreferrer"><AppleSVG /></a>&nbsp;
            <a href="https://open.spotify.com/album/7lezCvXmFjdptUUerT1rmu" target="_blank" rel="noopener noreferrer"><SpotifySVG /></a><br/>
            <a href="https://music.amazon.com/albums/B07Z2BK9CL" target="_blank" rel="noopener noreferrer"><AmazonSVG /></a>&nbsp;
            <a href="hhttps://music.youtube.com/browse/MPREb_R7x5ZOSTcoR" target="_blank" rel="noopener noreferrer"><YoutubeSVG /></a>
        </div>
        <div className="box g"><img src={fringeelementsJPG} alt="Fringe Elements" /></div>
        <div className="box h"><span className="boldText">Fringe Elements - EP [2019]</span><br/>Sometimes, trouble finds you.<br/><br/>
            <span className="boldText">TRACKLIST:</span><br/> [1] Remote Viewer<br/> [2] Spirit Guide<br/> [3] Animus<br/> [4] The Imposter<br/> [5] He Owns the Void<br/> [6] Anomalous<br/><br/>
            <a href="https://music.apple.com/us/album/fringe-elements-ep/1449215109" target="_blank" rel="noopener noreferrer"><AppleSVG /></a>&nbsp;
            <a href="https://open.spotify.com/album/30Qk0WBsmtAOnJG6W5sWqP" target="_blank" rel="noopener noreferrer"><SpotifySVG /></a><br/>
            <a href="https://music.amazon.com/albums/B07M7S96QQ" target="_blank" rel="noopener noreferrer"><AmazonSVG /></a>&nbsp;
            <a href="https://music.youtube.com/browse/MPREb_L77m3zBJYeA" target="_blank" rel="noopener noreferrer"><YoutubeSVG /></a>
        </div>
        <div className="box i"><img src={theymightbeliesJPG} alt="They Might Be Lies" /></div>
        <div className="box j"><span className="boldText">They Might Be Lies - EP [2018]</span><br/>Post-truth alternative facts.<br/><br/>
            <span className="boldText">TRACKLIST:</span><br/> [1] The Mob<br/> [2] Statelessness Protocol<br/> [3] Following You<br/> [4] Some Items May Have Been Removed<br/><br/>
            <a href="https://music.apple.com/us/album/they-might-be-lies-ep/1369591447" target="_blank" rel="noopener noreferrer"><AppleSVG /></a>&nbsp;
            <a href="https://open.spotify.com/album/2cn2rizERqZiM8f5yqgZqw" target="_blank" rel="noopener noreferrer"><SpotifySVG /></a><br/>
            <a href="https://music.amazon.com/albums/B07C1DSN4L" target="_blank" rel="noopener noreferrer"><AmazonSVG /></a>&nbsp;
            <a href="https://music.youtube.com/browse/MPREb_Y5fetoCWybN" target="_blank" rel="noopener noreferrer"><YoutubeSVG /></a>
        </div>
    </div>
  </div>
)

export default Discography