import React from 'react'
import '../css/Header.css';
const Header = () => {
  return (
      <div id="header">
        <span className="logo">REPLICAESSEMBLER</span>
    </div>
  )
}

export default Header;