import React from 'react'
import '../css/News.css'

const News = () => (
  <div>
    <div id="news" className="newsWrapper">
    <div id="curator-feed-default-layout"></div>
    </div>
    <div id="curatorLink"><a href="https://curator.io" target="_blank" rel="noopener noreferrer" className="crt-logo crt-tag">Powered by Curator.io</a></div>
  </div>                                                     
)
export default News