import React from 'react'
import { Switch, Route } from 'react-router-dom'
import '../css/Main.css';
import News from './News'
import About from './About'
import Discography from './Discography'
import Video from './Video'

const Main = () => (
    <div id="content">
      <main>
        <Switch>
          <Route exact path='/' component={News}/>
          <Route path='/about' component={About}/>
          <Route path='/discography' component={Discography}/>
          <Route path='/video' component={Video}/>
        </Switch>
      </main>
    </div>
)

export default Main