import React from 'react'
import '../css/Video.css'
import followingyouVIDEO from'../images/following-you-video.jpg';
import carnivalVIDEO from'../images/carnival-video.jpg';

const Video = () => (
  <div>
    <div id="video" className="videoWrapper">
      <div className="box v1"><a href="https://youtu.be/SpGkNmVJm1I" target="_blank" rel="noopener noreferrer"><img src={followingyouVIDEO} alt="Following You Video" /></a></div>
      <div className="box v2"><a href="https://youtu.be/Gk_TA_EFClQ" target="_blank" rel="noopener noreferrer"><img src={carnivalVIDEO} alt="Carnival Video" /></a></div>
      </div>
    </div>                                       
)
export default Video