import React from 'react'
import { Link } from 'react-router-dom';
import { contentTween } from './Resources';
import { twitterTween } from './Resources';
import { ReactComponent as AppleSVG } from '../images/apple.svg';
import { ReactComponent as SpotifySVG } from '../images/spotify.svg';
import { ReactComponent as AmazonSVG } from '../images/amazon.svg';
import { ReactComponent as YoutubeSVG } from '../images/youtube.svg';
import { ReactComponent as BandcampSVG } from '../images/bandcamp.svg';
import { ReactComponent as SoundcloudSVG } from '../images/soundcloud.svg';
import '../css/Nav.css';
const Nav = () => {
  return (
    <div>
        <div id="nav" role="navigation">
            <ul>
                <li>
                    <Link onClick={twitterTween} to="/">NEWS</Link>
                </li>
                <li>
                    <Link onClick={contentTween} to="/discography">MUSIC</Link>
                </li>
                <li>
                    <Link onClick={contentTween} to="/video">VIDEOS</Link>
                </li>
                <li>
                    <Link onClick={contentTween} to="/about">ABOUT</Link>
                </li>
            </ul>
        </div>
        <div id="social">
            <ul>
                <li>
                    <a href="https://music.apple.com/us/artist/replicaessembler/1369591449" target="_blank" rel="noopener noreferrer"><AppleSVG fill="#969B9D" /></a>
                </li>
                <li>
                    <a href="https://open.spotify.com/artist/0Ye88ax71AvdmtC2v2fDlQ" target="_blank" rel="noopener noreferrer"><SpotifySVG fill="#969B9D" /></a>
                </li>
                <li>
                    <a href="https://music.amazon.com/artists/B07C1DZYNP/replicaessembler" target="_blank" rel="noopener noreferrer"><AmazonSVG fill="#969B9D" /></a>
                </li>
                <li>
                    <a href="https://music.youtube.com/channel/UCR8GcFraJILb6De22FZHJIw" target="_blank" rel="noopener noreferrer"><YoutubeSVG fill="#969B9D" /></a>
                </li>
                <li>
                    <a href="https://replicaessembler.bandcamp.com" target="_blank" rel="noopener noreferrer"><BandcampSVG fill="#969B9D" /></a>
                </li>
                <li>
                    <a href="https://soundcloud.com/replicaessembler" target="_blank" rel="noopener noreferrer"><SoundcloudSVG fill="#969B9D" /></a>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Nav;