import {TweenMax} from "gsap/TweenMax";

window.twttr = (function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function(f) {
        t._e.push(f);
    };
    return t;
}(document, "script", "twitter-wjs"));
window.onload = function() {
    curatorLayout();
    window.twttr.ready(
        function (twttr) {
          window.twttr.widgets.load( document.getElementById("news") );
          var header = document.getElementById("header");
          var nav = document.getElementById("nav");
          var social = document.getElementById("social");
          var content = document.getElementById("content");
          var footer = document.getElementById("footer");
          TweenMax.fromTo(header, .25, {opacity:"0"}, {opacity:"1"});
          TweenMax.fromTo(nav, .5, {opacity:"0"}, {opacity:"1"});
          TweenMax.fromTo(social, .75, {opacity:"0"}, {opacity:"1"});
          TweenMax.fromTo(content, 1, {opacity:"0"}, {opacity:"1"});
          TweenMax.fromTo(footer, 1.25, {opacity:"0"}, {opacity:"1"});
        }
    );
};

export function callTwitter(){
    window.twttr.ready(
      function (twttr) {
        window.twttr.widgets.load( document.getElementById("news") );
          contentTween();
      }
    );
};
export function contentTween(){
    var content = document.getElementById("content");
    TweenMax.fromTo(content, 1, {opacity:"0"}, {opacity:"1"});
    var footer = document.getElementById("footer");
    TweenMax.fromTo(footer, 1.25, {opacity:"0"}, {opacity:"1"});
};
export function twitterTween(){
    curatorLayout();
};

export function curatorLayout(){
    var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
    i.src = "https://cdn.curator.io/published/23ee9c02-75b3-479b-81fe-a0254c6e40b6.js";
    e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
};